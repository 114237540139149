import { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useForm, SubmitHandler } from 'react-hook-form';
import CreditCardIframe from '../CreditCardIframe';
import initialize, {
  InitializePayload
} from '../../../../utils/Payments/FreedomPay/initialize';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';
import BillingInfoDisplay from '../BillingInfoDisplay/BillingInfoDisplay';
import {
  GatewayResponse,
  PaymentGatewayType,
  PaymentMethodType
} from '../../types/GatewayResponse';
import CollectBilling, {
  BillingFormValues
} from '../CollectBilling/CollectBilling';
import { GatewayError } from '../../GatewayError';
import { Country } from '../../../../types/countries';
import validate3DS from '../../../../utils/Payments/FreedomPay/validate3DS';
import constructValidatePayload from '../../../../utils/Payments/FreedomPay/constructValidatePayload';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { PricingOptions } from '../../../Cart/types';
import { OrderType } from '../../../../types/order';
import getCalculateObject from '../../../../utils/API/getCalculateObject';
import fetchFPCalculate from '../../../../utils/Payments/FreedomPay/fetchFPCalculate';
import { getErrorDialogText } from '../../../../utils/Payments/Stripe/errors';
import authorize, {
  AuthorizePayload
} from '../../../../utils/Payments/FreedomPay/authorize';
import extractSessionKey from '../../../../utils/Payments/FreedomPay/extractSessionKey';
import ProcessingOrderDialog from '../../../Checkout/components/ProcessingOrderDialog/ProcessingOrderDialog';
import RetryOrderDialog from '../../../Checkout/components/RetryOrderDialog/RetryOrderDialog';
import fetchNextOrderId from '../../../../utils/Payments/FreedomPay/fetchNextOrderId';
import CircularProgress from '@mui/material/CircularProgress';

interface CreditCardProps {
  siteId: string;
  storeId: string;
  menuId: number;
  transactionTotal: number;
  orderTimeStamp: string;
  countries: Country[];
  sessionTimeout: () => void;
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const Container = styled('div')({
  zIndex: 1000,
  width: '100%'
});

const IframeContainer = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'isVisible'
})<{ isVisible: boolean }>(({ isVisible }) => ({
  width: '100%',
  marginTop: '16px',
  display: isVisible ? 'inline-block' : 'none'
}));

const StyledLoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.colors.base.black,
  marginTop: theme.spacing(4)
}));

const CreditCard = ({
  siteId,
  storeId,
  menuId,
  transactionTotal,
  orderTimeStamp,
  countries,
  sessionTimeout,
  onError,
  onSuccess
}: CreditCardProps) => {
  const { partnerConfig } = useSiteConfig();
  const { items: cartItems, priceToDisplay } = useCartV2();

  const [billingInfo, setBillingInfo] = useState<BillingFormValues | null>(
    null
  );
  const [iframe, setIframe] = useState<string | null>();
  const [isIframeVisible, setIsIframeVisible] = useState<boolean>(true);
  const [isIframeInitializing, setIsIframeInitializing] = useState(false);

  const [isEditing, setIsEditing] = useState<boolean>(true);
  const { setError, clearErrors } = useForm<BillingFormValues>();

  const [paymentProcessing, setPaymentProcessing] = useState(false);

  const [showRetryDialog, setShowRetryDialog] = useState(false);

  const payment3dsData = useRef<unknown | null>(null);

  const nextOrderId = useRef<number | null>(null);

  const payment3dsEnabled = useRef<boolean>(true);

  // 5.02 needs to stop at 3ds call and show an error
  // 5.08 needs to stop at 3ds call and show an error
  // 5.09 needs payments to fail with 424 (reason code 217) and then succeed

  const onIframeError = async (data: unknown) => {
    try {
      await validate3DS(constructValidatePayload(payment3dsData.current));
    } catch (err) {
      const errorResponse = getErrorDialogText('processing_error');

      //@todo: figure out what do we show if 3ds fails...
      onError(
        new GatewayError(
          'unknown',
          PaymentMethodType.Card,
          errorResponse.title,
          errorResponse.description,
          undefined
        )
      );
      return;
    }
  };

  // 3ds data fires first, so we need to keep it temporarily
  const onReceive3dsData = async (data: unknown) => {
    payment3dsData.current = data;
  };

  const onReceivePaymentKey = async (key: string) => {
    // once we get the payment key, we should already have the 3ds data
    // so we can start the payment process

    setPaymentProcessing(true);

    setIsIframeVisible(false);

    if (
      !payment3dsData.current &&
      transactionTotal === 55.22 &&
      payment3dsEnabled.current === true
    ) {
      payment3dsEnabled.current = false;
    }

    let abortPayment = false;
    try {
      if (payment3dsEnabled.current) {
        await validate3DS(constructValidatePayload(payment3dsData.current));
      }
    } catch (err) {
      abortPayment = true;

      const errorResponse = getErrorDialogText('processing_error');

      //@todo: figure out what do we show if 3ds fails...
      onError(
        new GatewayError(
          'unknown',
          PaymentMethodType.Card,
          errorResponse.title,
          errorResponse.description,
          undefined
        )
      );
      return;
    }

    if (abortPayment) {
      return;
    }

    try {
      const calculateObject = getCalculateObject(
        cartItems,
        priceToDisplay === PricingOptions.TAKEOUT
          ? OrderType.takeOut
          : OrderType.dineIn,
        null,
        menuId
      );

      const calculateResponse = await fetchFPCalculate(
        storeId,
        calculateObject
      );

      const nameOnCard = `${billingInfo?.BillingFirstName} ${billingInfo?.BillingLastName}`;
      const sessionKey = extractSessionKey(iframe as string);

      const authorizePayload: AuthorizePayload = {
        PaymentKey: key,
        PosSyncAttemptNumber: 1,
        orderTimeStamp,
        nameOnCard,
        invoiceNumber: nextOrderId.current!.toString(),
        chargeAmount: calculateResponse.chargeAmount,
        taxTotal: calculateResponse.taxTotal,
        items: calculateResponse.items,
        bearerSessionKey: sessionKey,
        channel: 'mobile'
      };

      const authorizeResponse = await authorize(siteId, authorizePayload);

      if (authorizeResponse.decision === 'ACCEPT') {
        setShowRetryDialog(false);

        payment3dsData.current = null;

        onSuccess({
          type: 'credit_card',
          amount: transactionTotal,
          confirmationId: authorizeResponse.requestID,
          gateway: PaymentGatewayType.freedompay,
          paymentMethod: PaymentMethodType.Card,
          orderId: nextOrderId.current!
        });
      } else {
        const errorResponse = getErrorDialogText('processing_error');
        onError(
          new GatewayError(
            'declined',
            PaymentMethodType.Card,
            errorResponse.title,
            errorResponse.description,
            undefined
          )
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.response?.status === 408) {
        // 408 === payment timeout
        setPaymentProcessing(false);
        setShowRetryDialog(true);
        return;
      }

      if (
        err.response.status === 424 &&
        err.response.data.message.includes('Reason Code: 217')
      ) {
        if (window.FreedomPay) {
          payment3dsEnabled.current = true;
          window.FreedomPay.Apm.ConsumerAuth.invoke({ mandateChallenge: true });
        }

        return;
      }

      const gatewayError = err.response?.data || {};
      onError(
        new GatewayError(
          gatewayError.message,
          PaymentMethodType.Card,
          null,
          null,
          undefined,
          gatewayError.errorCode
        )
      );
    }
  };

  const initializeIframe = async (billing: BillingFormValues) => {
    setIsIframeInitializing(true);
    const nextId = await fetchNextOrderId(storeId);

    if (!nextId) {
      const errorResponse = getErrorDialogText('processing_error');

      //@todo: figure out what do we show if fetchNextOrderId fails...
      onError(
        new GatewayError(
          'unknown',
          PaymentMethodType.Card,
          errorResponse.title,
          errorResponse.description,
          undefined
        )
      );

      setIsIframeInitializing(false);
      return;
    }

    nextOrderId.current = nextId;

    const initializePayload: InitializePayload = {
      OrderDetails: {
        TransactionTotal: transactionTotal,
        OrderNumber: nextOrderId.current,
        OrderDescription: priceToDisplay
      },
      BillingAddress1: billing.BillingAddress1,
      BillingCity: billing.BillingCity,
      BillingCountryCode: billing.Country!.num3,
      BillingFirstName: billing.BillingFirstName,
      BillingLastName: billing.BillingLastName,
      BillingPostalCode: billing.BillingPostalCode,
      Email: billing.Email,
      MobilePhone: billing.MobilePhone,
      Country: billing.Country!.alpha2
    };

    if (transactionTotal === 55.22) {
      initializePayload.disableConsumerAuthentication = true;
    }

    const response = await initialize(
      partnerConfig.partnerId,
      initializePayload
    );

    if (response?.iFrame) {
      setIframe(response.iFrame);
      setIsIframeVisible(true);
      setIsIframeInitializing(false);
    }
  };

  const onRetry = async () => {
    payment3dsData.current = null;

    setShowRetryDialog(false);

    if (billingInfo) {
      await initializeIframe(billingInfo);
      return;
    }

    setIsEditing(true);
    setIsIframeVisible(false);
  };

  const billingOnSubmit: SubmitHandler<BillingFormValues> = async (
    formValues
  ) => {
    if (!formValues.Country) {
      setError('Country', {
        type: 'manual',
        message: 'Country is required'
      });
      return;
    } else {
      clearErrors('Country');
    }

    setBillingInfo(formValues);
    setIsEditing(false);

    await initializeIframe(formValues);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setIsIframeVisible(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setIsIframeVisible(true);
  };

  return (
    <Container>
      {isEditing ? (
        <CollectBilling
          handleCancelClick={handleCancelClick}
          billingOnSubmit={billingOnSubmit}
          initialValues={billingInfo}
          countries={countries}
        />
      ) : (
        <>
          {billingInfo !== null && (
            <BillingInfoDisplay
              billingInfo={billingInfo}
              handleEditClick={handleEditClick}
            />
          )}
        </>
      )}

      {isIframeInitializing && <StyledLoadingSpinner />}

      {iframe && (
        <IframeContainer isVisible={isIframeVisible}>
          <CreditCardIframe
            onIframeError={onIframeError}
            onReceivePaymentKey={onReceivePaymentKey}
            onReceive3dsData={onReceive3dsData}
            iframeHtml={iframe}
            sessionTimeout={sessionTimeout}
          />
        </IframeContainer>
      )}

      {paymentProcessing ? <ProcessingOrderDialog /> : null}
      {showRetryDialog ? <RetryOrderDialog onRetry={onRetry} /> : null}
    </Container>
  );
};

export default CreditCard;
